import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography,
    styled,
} from '@mui/material';

import { ChevronDown } from 'react-feather';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-5519c33536/icons';
import { pxToRem } from '../../../../utils/style-functions';
import { useUsers } from '../../../../custom-providers/UsersProvider';

const CommunicationItemWrapper = styled('div')(({ theme }) => ({
    marginBottom: theme.spacing(2),
}));

const CommunicationWrapper = styled('div')(() => ({}));

const Title = styled('h2')(() => ({
    fontSize: pxToRem(20),
    lineHeight: pxToRem(30),
    fontWeight: 'normal',
}));

export const Communication = () => {
    const {
        state: { teamInsights },
    } = useUsers();

    if (!teamInsights?.learner) {
        return null;
    }
    const { cogTypeContent } = teamInsights.learner;

    if (!cogTypeContent) {
        return (
            <Typography>Sorry no communication tips are available</Typography>
        );
    }

    return (
        <CommunicationWrapper>
            {cogTypeContent.communicationTips.map((tip) => (
                <CommunicationItemWrapper key={tip.type}>
                    <Accordion
                        disableGutters
                        elevation={0}
                        sx={{
                            '&:before': {
                                display: 'none',
                            },
                        }}
                    >
                        <AccordionSummary expandIcon={<ChevronDown />}>
                            <Title>
                                {tip.appIcon && (
                                    <span style={{ marginRight: '8px' }}>
                                        <FontAwesomeIcon
                                            icon={
                                                byPrefixAndName.far[tip.appIcon]
                                            }
                                        />
                                    </span>
                                )}
                                {tip.type}
                            </Title>
                        </AccordionSummary>
                        <AccordionDetails>
                            {tip.tips.map((t) => (
                                <Typography key={t}>{t}</Typography>
                            ))}
                        </AccordionDetails>
                    </Accordion>
                </CommunicationItemWrapper>
            ))}
        </CommunicationWrapper>
    );
};
