import {
    IBulkConfigUser,
    useUsers,
} from '../../../custom-providers/UsersProvider';
import {
    ICoach,
    IUserFilters,
    IUsersResponse,
    UserFilterType,
} from '../../../types/user.types';
import React, { useEffect, useState } from 'react';
import { initialState, reducer } from '../../../state/table-state';

import { ArrowRight } from 'react-feather';
import { BulkUpdateManagerDialog } from '../../../components/main-app/users/dialogs-drawers/BulkUpdateManagerDialog';
import { BulkUpdateSecondaryManagerDialog } from '../../../components/main-app/users/dialogs-drawers/BulkUpdateSecondaryManagerDialog';
import { Button } from '@cognassist/react-components';
import { DeleteUser } from '../../../components/main-app/users/dialogs-drawers/DeleteUser';
import { Drawer } from '@mui/material';
import { Edit } from '../../../components/main-app/users/Edit';
import { FilterDrawer } from '../../../components/main-app/users/FilterDrawer';
import { InviteUsersDialog } from '../../../components/main-app/users/dialogs-drawers/InviteUsersDialog';
import { MainContentInner } from '../../../components/styled-components/Wrappers';
import PageHeader from '../../../components/PageHeader';
import PageNotFound from '../../../pages/PageNotFound';
import { RemoveProfilePictureDialog } from '../../../components/main-app/users/dialogs-drawers/RemoveProfilePictureDialog';
import { UsersTable } from '../../../components/main-app/users/UsersTable';
import { endpoints } from '../../../api/endpoints';
import { getDefaultPageSize } from '../../../utils/tables';
import { useChampionNotifications } from '../../../custom-providers/ChampionNotificationsProvider';
import { useDebounce } from 'use-debounce';
import { useEffectAfterMount } from '../../../custom-hooks/useEffectAfterMount';
import { useImmerReducer } from 'use-immer';
import { useIsAdmin } from '../../../custom-hooks/useIsAdmin';
import { useSnackbar } from 'notistack';
import { useUserFilters } from '../../../custom-hooks/useUserFilters';

interface IOwnProps {
    pageTitle: string;
    isMyTeam: boolean;
}

export const ViewUsers: React.FC<IOwnProps> = ({ pageTitle, isMyTeam }) => {
    const [pageSize, setPageSize] = useState<number>(
        getDefaultPageSize('usersTablePageSize', 10)
    );

    const [searchText, setSearchText] = useState<string>('');
    const [debouncedSearchText] = useDebounce(searchText, 500);
    const { getCoaches, getUsers } = endpoints();

    const [coaches, setCoaches] = useState<ICoach[]>([]);
    const [usersData, setUsersData] = useState<IUsersResponse>();
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<boolean>(false);

    const { parseActiveFilterByType } = useUserFilters({});

    const {
        state: { activeFilters, enterpriseUser },
        dispatch: dispatchUsers,
    } = useUsers();

    const isAdmin = useIsAdmin();

    const { enqueueSnackbar } = useSnackbar();

    const [
        {
            startRecord,
            totalRecords,
            totalPages,
            currentPage,
            currentPageRecords,
        },
        dispatch,
    ] = useImmerReducer(reducer, initialState);

    const [activeUserId, setActiveUserId] = useState<string>();

    const [bulkUsers, setBulkUsers] = useState<IBulkConfigUser[] | null>(null);
    const [bulkTotal, setBulkTotal] = useState<number | null>(null);

    const { refreshState: refreshChampionNotifications } =
        useChampionNotifications();

    useEffect(() => {
        document.title = 'Users';
        if (isAdmin) {
            getCoachData();
        }
    }, []);

    useEffect(() => {
        if (currentPage && pageSize) {
            updateUsers();
        }
    }, [currentPage, pageSize]);

    useEffectAfterMount(() => {
        if (currentPage !== 1) {
            dispatch({
                type: 'UPDATE_CURRENT_PAGE',
                payload: { pageNumber: 1 },
            });
        } else {
            updateUsers();
        }
    }, [debouncedSearchText, activeFilters]);

    useEffect(() => {
        if (usersData) {
            dispatch({
                type: 'SET_TOTAL_PAGES',
                payload: {
                    totalPages: Math.ceil(usersData.totalRecords / pageSize),
                    endRecord: startRecord + usersData.users.length - 1,
                },
            });
            dispatch({
                type: 'SET_TOTAL_RECORDS',
                payload: usersData.totalRecords,
            });
            dispatch({
                type: 'SET_TOTAL_CURRENT_PAGE_RECORDS',
                payload: usersData.users.length,
            });
        }
    }, [usersData]);

    const getCoachData = async () => {
        const { data, error } = await getCoaches();

        if (error) {
            enqueueSnackbar(
                'Something went wrong. Cannot retrieve list of managers at this time.',
                {
                    variant: 'error',
                }
            );
        }
        if (data) {
            setCoaches(data.coaches);
        }
    };

    const getLatestFilterParams = (): IUserFilters => {
        return {
            page: currentPage.toString(),
            pageSize: pageSize.toString(),
            searchText: debouncedSearchText,
            roleFilter: parseActiveFilterByType(UserFilterType.Role),
            assessmentStatusFilter: parseActiveFilterByType(
                UserFilterType.AssessmentStatus
            ),
            managerUserIdFilter: parseActiveFilterByType(
                UserFilterType.Manager
            ),
            secondaryManagerUserIdFilter: parseActiveFilterByType(
                UserFilterType.SecondaryManager
            ),
            filterToMyTeam:
                (isAdmin && isMyTeam) ||
                activeFilters.some(
                    (f) => f.filterType == UserFilterType.MyTeam
                ),
        };
    };

    const updateUsers = async () => {
        setLoading(true);
        const { data } = await getUsers(getLatestFilterParams());

        if (data) {
            setUsersData(data);
        } else {
            setError(true);
        }
        setLoading(false);
    };

    const handleCloseEditUser = (event: { updateUserTable: boolean }) => {
        if (enterpriseUser?.profilePicture) {
            URL.revokeObjectURL(enterpriseUser?.profilePicture);
        }

        dispatchUsers({
            type: 'SET_CHAMPION_ROLE_WARNING',
            payload: {
                showWarning: false,
                warningOverridden: false,
                disableSave: false,
            },
        });

        setActiveUserId(undefined);
        refreshChampionNotifications();

        if (event.updateUserTable) {
            updateUsers();
        }
    };

    if (error) {
        return <PageNotFound showTopNav={false} />;
    }

    return (
        <>
            <PageHeader
                title={pageTitle}
                action={
                    <Button
                        text='Invite users'
                        endIcon={<ArrowRight />}
                        onClick={() =>
                            dispatchUsers({
                                type: 'SET_DIALOG_MANAGEMENT',
                                payload: {
                                    key: 'inviteUsers',
                                    value: true,
                                },
                            })
                        }
                    />
                }
            />
            <MainContentInner>
                <div>
                    <UsersTable
                        usersData={usersData}
                        totalRecords={totalRecords}
                        totalPages={totalPages}
                        currentPageRecords={currentPageRecords}
                        setPageSize={setPageSize}
                        setSearchText={setSearchText}
                        searchText={searchText}
                        pageSize={pageSize}
                        setActiveUserId={setActiveUserId}
                        currentPage={currentPage}
                        tableDispatch={dispatch}
                        loading={loading}
                        isMyTeam={isMyTeam}
                        setBulkUsers={setBulkUsers}
                        setBulkTotal={setBulkTotal}
                    />
                </div>

                <Drawer
                    open={!!activeUserId}
                    anchor='right'
                    onClose={handleCloseEditUser}
                >
                    <Edit
                        handleCloseEditUser={handleCloseEditUser}
                        userId={activeUserId}
                        updateUsers={updateUsers}
                    />
                </Drawer>

                <FilterDrawer isMyTeam={isMyTeam} />

                <InviteUsersDialog />

                <DeleteUser handleCloseEditUser={handleCloseEditUser} />

                {isAdmin && (
                    <>
                        <BulkUpdateManagerDialog
                            coaches={coaches}
                            getLatestFilterParams={getLatestFilterParams}
                            bulkUsers={bulkUsers}
                            bulkTotal={bulkTotal}
                            setBulkTotal={setBulkTotal}
                            setBulkUsers={setBulkUsers}
                        />

                        <BulkUpdateSecondaryManagerDialog
                            coaches={coaches}
                            getLatestFilterParams={getLatestFilterParams}
                            bulkUsers={bulkUsers}
                            bulkTotal={bulkTotal}
                            setBulkTotal={setBulkTotal}
                            setBulkUsers={setBulkUsers}
                        />

                        <RemoveProfilePictureDialog
                            handleCloseEditUser={handleCloseEditUser}
                        />
                    </>
                )}
            </MainContentInner>
        </>
    );
};
