import { CircularProgress, Typography, styled, useTheme } from '@mui/material';
import { SyntheticEvent, useEffect, useLayoutEffect, useState } from 'react';
import {
    Tab,
    TabRowsMaxWidth,
    Tabs,
    TabsRow,
} from '../../../components/styled-components/Tabs';

import CognitionDeepDive from '../cognitive-map-v2/deep-dive';
import CognitiveMapOverview from '../cognitive-map-v2/overview';
import { ContentWrapper } from '../../../components/main-app/cognitive-map-v2/shared-components';
import { getScreenSizeFromWidth } from '../../../components/main-app/cognitive-map-v2/helpers';
import { pxToRem } from '../../../utils/style-functions';
import { useCognitiveMap } from '../../../custom-providers/CognitiveMapProvider';
import { useLearnerReport } from '../../../custom-providers/LearnerReportProvider';
import useResizeObserver from '@react-hook/resize-observer';

const LoadingWrapper = styled('div')(() => ({
    height: 'calc(100vh - 80px)',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const TitleWrapper = styled('div')(({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    backgroundColor: theme.palette.common.white,
}));

const InnerTitleWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(2, 4),
    maxWidth: 1440,
    width: '100%',
    margin: '0 auto',
}));

enum CurrentTab {
    Overview = 1,
    DeepDive = 2,
}

const LearnerReport: React.FC = () => {
    const theme = useTheme();

    const [wrapper, setWrapper] = useState<HTMLElement | null>(null);
    const [tab, setTab] = useState<CurrentTab>(CurrentTab.Overview);

    const {
        state: { cognitiveMapV2 },
        dispatch,
    } = useCognitiveMap();

    const {
        state: { learnerName },
    } = useLearnerReport();

    useEffect(() => {
        document.title = 'My Cognitive Map';
    }, []);

    useLayoutEffect(() => {
        if (wrapper) {
            const { width } = wrapper.getBoundingClientRect();
            dispatch({
                type: 'SET_SCREEN_SIZE',
                payload: getScreenSizeFromWidth(width),
            });
        }
    }, [wrapper]);

    useResizeObserver(wrapper, (entry) => {
        const { width } = entry.contentRect;
        dispatch({
            type: 'SET_SCREEN_SIZE',
            payload: getScreenSizeFromWidth(width),
        });
    });

    const handleTabChange = (_event: SyntheticEvent, newValue: CurrentTab) => {
        setTab(newValue);
    };

    const getTabContent = () => {
        switch (tab) {
            case CurrentTab.Overview:
                return <CognitiveMapOverview fullName={learnerName} />;
            case CurrentTab.DeepDive:
                return <CognitionDeepDive includeSidebarTopGap={false} />;
            default:
                return null;
        }
    };

    if (!cognitiveMapV2) {
        return (
            <LoadingWrapper>
                <CircularProgress />
            </LoadingWrapper>
        );
    }

    return (
        <>
            <TitleWrapper>
                <InnerTitleWrapper>
                    <Typography
                        variant='h1'
                        sx={{
                            fontWeight: 600,
                            fontSize: pxToRem(24),
                            lineHeight: pxToRem(36),
                        }}
                    >
                        My Cognitive Map
                    </Typography>
                </InnerTitleWrapper>
            </TitleWrapper>
            <TabsRow>
                <TabRowsMaxWidth paddingTop={theme.spacing(2)}>
                    <Tabs
                        value={tab}
                        onChange={handleTabChange}
                        aria-label='cognitive map tabs'
                        variant='scrollable'
                        scrollButtons='auto'
                    >
                        <Tab value={CurrentTab.Overview} label='Overview' />
                        <Tab
                            value={CurrentTab.DeepDive}
                            label='Cognition Deep Dive'
                        />
                    </Tabs>
                </TabRowsMaxWidth>
            </TabsRow>
            <ContentWrapper ref={setWrapper}>{getTabContent()}</ContentWrapper>
        </>
    );
};

export default LearnerReport;
