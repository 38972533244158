import {
    CircularProgress,
    FormControl as MuiFormControl,
    InputLabel as MuiInputLabel,
    Pagination,
    SelectChangeEvent,
    styled,
} from '@mui/material';

import CourseCard from '../../../../components/CourseCard';
import { DomainSelect } from '../../../../components/main-app/cognitive-map/DomainSelect';
import { PageWrapper } from '../../../../components/main-app/cognitive-map-v2/shared-components';
import { getMinMaxGridColumns } from '../../../../utils/grid-functions';
import { pxToRem } from '../../../../utils/style-functions';
import { shouldForwardProps } from '../../../../utils/shouldForwardProp';
import { useCognitiveMap } from '../../../../custom-providers/CognitiveMapProvider';
import { useStrategies } from '../../../../custom-hooks/useStrategies';

const LoadingWrapper = styled('div')(() => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
}));

const SelectWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'flex-start',
    flexFlow: 'column nowrap',
    marginBottom: theme.spacing(3),
}));

const InputLabel = styled(MuiInputLabel)(({ theme }) => ({
    marginBottom: theme.spacing(1),
    fontSize: pxToRem(16),
    lineHeight: pxToRem(24),
    fontWeight: 600,
    color: theme.palette.text.primary,
}));

const FormControl = styled(MuiFormControl, {
    ...shouldForwardProps('isMobile'),
})<{ isMobile: boolean }>(({ isMobile }) => ({
    minWidth: isMobile ? '100%' : 350,
}));

const StrategiesWrapper = styled('div')(() => {
    const gap = 16;
    const maxColumns = 3;
    const minWidth = 300;

    return getMinMaxGridColumns(gap, maxColumns, minWidth);
});

const PaginationWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(3),
}));

const CognitiveMapStrategies: React.FC = () => {
    const {
        state: { screenSize, caiDrawerOpen },
    } = useCognitiveMap();

    const {
        domain,
        setDomain,
        domains,
        strategies,
        currentPage,
        setCurrentPage,
        totalPages,
    } = useStrategies(caiDrawerOpen);

    const updatePageNumber = (
        _event: React.ChangeEvent<unknown>,
        value: number
    ) => {
        setCurrentPage(value);
    };

    const updateDomain = (e: SelectChangeEvent<unknown>) => {
        setDomain(parseInt(e.target.value as string));
        setCurrentPage(1);
    };

    if (!domains?.length) {
        return (
            <LoadingWrapper>
                <CircularProgress />
            </LoadingWrapper>
        );
    }

    return (
        <PageWrapper
            isMobile={screenSize.isMobile}
            isTablet={screenSize.isTablet}
        >
            <SelectWrapper>
                <InputLabel id='select-domain-label'>
                    Choose a domain
                </InputLabel>
                <FormControl size='small' isMobile={screenSize.isMobile}>
                    <DomainSelect
                        domain={domain}
                        domains={domains}
                        updateDomain={updateDomain}
                    />
                </FormControl>
            </SelectWrapper>
            <StrategiesWrapper id='strategy-cards'>
                {strategies.map((strategy) => (
                    <CourseCard key={strategy.courseUrl} course={strategy} />
                ))}
            </StrategiesWrapper>
            {totalPages > 1 && (
                <PaginationWrapper>
                    <Pagination
                        page={currentPage}
                        onChange={updatePageNumber}
                        count={totalPages}
                        boundaryCount={6}
                    />
                </PaginationWrapper>
            )}
        </PageWrapper>
    );
};

export default CognitiveMapStrategies;
