import {
    CogTypeEnum,
    ICognitiveMapV2,
    IProcessingBiasData,
    IScreenSize,
} from '../../../types';

import flexibleOperatorIllustration from '../../../assets/img/cogtype-banners/illustrations/flexible-operator.svg';
import flexibleOperatorMask from '../../../assets/img/cogtype-banners/masks/flexible-operator.svg';
import highFiveIllustration from '../../../assets/img/cogtype-banners/illustrations/high-five.svg';
import ideaSynthesizerIllustration from '../../../assets/img/cogtype-banners/illustrations/idea-synthesizer.svg';
import ideaSynthesizerMask from '../../../assets/img/cogtype-banners/masks/idea-synthesizer.svg';
import languageLibrarianIllustration from '../../../assets/img/cogtype-banners/illustrations/language-librarian.svg';
import languageLibrarianMask from '../../../assets/img/cogtype-banners/masks/language-librarian.svg';
import patternMapperIllustration from '../../../assets/img/cogtype-banners/illustrations/pattern-mapper.svg';
import patternMapperMask from '../../../assets/img/cogtype-banners/masks/pattern-mapper.svg';
import { pxToRem } from '../../../utils/style-functions';
import rectangleMask from '../../../assets/img/rectangle-mask.svg';
import thoughtSculptorIllustration from '../../../assets/img/cogtype-banners/illustrations/thought-sculptor.svg';
import thoughtSculptorMask from '../../../assets/img/cogtype-banners/masks/thought-sculptor.svg';
import visualNavigatorIllustration from '../../../assets/img/cogtype-banners/illustrations/visual-navigator.svg';
import visualNavigatorMask from '../../../assets/img/cogtype-banners/masks/visual-navigator.svg';
import visualSprinterIllustration from '../../../assets/img/cogtype-banners/illustrations/visual-sprinter.svg';
import visualSprinterMask from '../../../assets/img/cogtype-banners/masks/visual-sprinter.svg';
import wordStrategistIllustration from '../../../assets/img/cogtype-banners/illustrations/word-strategist.svg';
import wordStrategistMask from '../../../assets/img/cogtype-banners/masks/word-strategist.svg';
import wordWeaverIllustration from '../../../assets/img/cogtype-banners/illustrations/word-weaver.svg';
import wordWeaverMask from '../../../assets/img/cogtype-banners/masks/word-weaver.svg';

export const getScreenSizeFromWidth = (width: number): IScreenSize => ({
    isMobile: width < 480,
    isTablet: width >= 480 && width <= 890,
});

export const getFontSize = (isMobile: boolean) =>
    isMobile ? pxToRem(18) : pxToRem(24);

export const getLineHeight = (isMobile: boolean) =>
    isMobile ? pxToRem(24) : pxToRem(36);

export const mapCogMapV2Data = (
    cognitiveMapData: ICognitiveMapV2,
    processingBiasData: IProcessingBiasData
): ICognitiveMapV2 => {
    return {
        domains: [...cognitiveMapData.domains],
        staticContent: {
            ...cognitiveMapData.staticContent,
            processInformationVisual: {
                ...cognitiveMapData.staticContent.processInformationVisual,
                scores: {
                    processingBiasZ: processingBiasData.processingBiasZ,
                    memoryIndexAdjustedZ:
                        processingBiasData.memoryIndexAdjustedZ,
                },
            },
        },
        cogType: { ...cognitiveMapData.cogType },
    };
};

export const determineCogtypeBannerStyle = (cogTypeId: CogTypeEnum | null) => {
    switch (cogTypeId) {
        case 1:
            return {
                image: wordWeaverIllustration,
                mask: wordWeaverMask,
                color: '#E0D2FF',
            };
        case 2:
            return {
                image: flexibleOperatorIllustration,
                mask: flexibleOperatorMask,
                color: '#FFEA9A',
            };
        case 3:
            return {
                image: visualSprinterIllustration,
                mask: visualSprinterMask,
                color: '#D9E8FD',
            };
        case 4:
            return {
                image: wordStrategistIllustration,
                mask: wordStrategistMask,
                color: '#E0D2FF',
            };
        case 5:
            return {
                image: ideaSynthesizerIllustration,
                mask: ideaSynthesizerMask,
                color: '#FFEA9A',
            };
        case 6:
            return {
                image: patternMapperIllustration,
                mask: patternMapperMask,
                color: '#D9E8FD',
            };
        case 7:
            return {
                image: languageLibrarianIllustration,
                mask: languageLibrarianMask,
                color: '#E0D2FF',
            };
        case 8:
            return {
                image: thoughtSculptorIllustration,
                mask: thoughtSculptorMask,
                color: '#FFEA9A',
            };
        case 9:
            return {
                image: visualNavigatorIllustration,
                mask: visualNavigatorMask,
                color: '#D9E8FD',
            };
        default:
            return {
                image: highFiveIllustration,
                mask: rectangleMask,
                color: '#FFEA9A',
            };
    }
};
