import { FormControl, MenuItem, Select } from '@mui/material';

import { CogInput } from '../../../../styled-components';
import { Field } from '../../../pre-assessment/Field';
import { WorkEnvironment } from '../../../../../types';
import { useFormContext } from 'react-hook-form';

const options = [
    { label: 'Home worker', value: WorkEnvironment.HomeWorker },
    { label: 'Office worker', value: WorkEnvironment.OfficeWorker },
    { label: 'Field worker', value: WorkEnvironment.FieldWorker },
    { label: 'Hybrid worker', value: WorkEnvironment.HybridWorker },
];

interface IOwnProps {
    defaultValue: WorkEnvironment | '';
    isSmall?: boolean;
}

export const WorkEnvironmentSelect: React.FC<IOwnProps> = ({
    defaultValue,
    isSmall,
}) => {
    const {
        formState: { errors },
        register,
    } = useFormContext<{ workEnvironment: WorkEnvironment | '' }>();

    const validate = (v: WorkEnvironment | '') =>
        ['', 10, 20, 30, 40].includes(v);

    return (
        <Field
            label='Work environment'
            labelId='workEnvironmentLabel'
            hasError={!!errors.workEnvironment}
            isSmall={isSmall}
        >
            <FormControl fullWidth>
                <Select
                    {...register('workEnvironment', {
                        validate: validate,
                    })}
                    defaultValue={defaultValue}
                    labelId='workEnvironmentLabel'
                    input={
                        <CogInput
                            inputProps={{
                                'aria-labelledby': 'workEnvironmentLabel',
                            }}
                            hasError={!!errors.workEnvironment}
                        />
                    }
                >
                    {options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Field>
    );
};
