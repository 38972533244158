import { Dialog, DialogTitle, styled } from '@mui/material';
import { useEffect, useState } from 'react';

import { InvitationOnlyFlow } from '../invitation-flows/InvitationOnlyFlow';
import { OpenInvitationFlow } from '../invitation-flows/OpenInvitationFlow';
import { Roles } from '../../../../types';
import { Switch } from '@cognassist/react-components';
import { X } from 'react-feather';
import { endpoints } from '../../../../api/endpoints';
import { useAuth } from '../../../../custom-providers/AuthProvider';
import { useIsManagerNotAdmin } from '../../../../custom-hooks/useIsManagerNotAdmin';
import { useUsers } from '../../../../custom-providers/UsersProvider';

const InviteTitleWrapper = styled(`div`)(() => ({
    display: 'flex',
    justifyContent: 'space-between',
}));

const InviteCloseIcon = styled(`div`)(({ theme }) => ({
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
}));

const InviteFlowToggleWrapper = styled(`div`)(({ theme }) => ({
    display: 'flex',
    marginLeft: theme.spacing(3),
    paddingBottom: theme.spacing(2),
}));

const InviteFlowToggleText = styled('div')(({ theme }) => ({
    marginRight: theme.spacing(2),
    fontWeight: 'bold',
}));

const CloseIcon = styled(X)(() => ({
    cursor: 'pointer',
}));

export const InviteUsersDialog: React.FC = () => {
    const {
        state: {
            userConfig: { roles },
        },
    } = useAuth();

    const {
        state: {
            dialogManagement: { inviteUsers: open },
        },
        dispatch,
    } = useUsers();

    const close = () => {
        dispatch({
            type: 'SET_DIALOG_MANAGEMENT',
            payload: {
                key: 'inviteUsers',
                value: false,
            },
        });
    };

    const { patchClientOnlyAllowInvitedUsers, getInvitationMetadata } =
        endpoints();

    const isManagerNotAdmin = useIsManagerNotAdmin();

    const [inviteLink, setInviteLink] = useState<string>('');
    const [invitationOnly, setInvitationOnly] = useState<boolean>();
    const [validDomains, setValidDomains] = useState<string[]>([]);
    const [restrictDomains, setRestrictDomains] = useState<boolean>(true);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const { data } = await getInvitationMetadata();

        if (data) {
            setInvitationOnly(data.onboardByInvitationOnly);
            setInviteLink(data.onboardingLink);
            setValidDomains(data.validEmailDomains);
            setRestrictDomains(data.restrictEmailDomains);
        }
    };

    useEffect(() => {
        if (invitationOnly !== undefined) {
            patchClientOnlyAllowInvitedUsers({
                onlyAllowInvitedUsers: invitationOnly,
            });
        }
    }, [invitationOnly]);

    return (
        <Dialog open={open} onClose={close}>
            <InviteTitleWrapper>
                <DialogTitle>Invite users</DialogTitle>
                <InviteCloseIcon
                    onClick={() => {
                        close();
                    }}
                >
                    <CloseIcon />
                </InviteCloseIcon>
            </InviteTitleWrapper>
            {(roles?.includes(Roles.ClientAdmin) ||
                roles?.includes(Roles.SysAdmin)) && (
                <InviteFlowToggleWrapper>
                    <InviteFlowToggleText>
                        Email invite only
                    </InviteFlowToggleText>
                    <label
                        id='InvitationFlowSwitchLabel'
                        htmlFor='InvitationFlowSwitch'
                        className='visually-hidden'
                    >
                        Toggle onboarding flow
                    </label>
                    <Switch
                        inputProps={{
                            id: 'InvitationFlowSwitch',
                            'aria-labelledby': 'InvitationFlowSwitchLabel',
                        }}
                        role='button'
                        id='invitationFlowSwitch'
                        checked={invitationOnly}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setInvitationOnly(e.target.checked);
                        }}
                    />
                </InviteFlowToggleWrapper>
            )}
            {invitationOnly ? (
                <InvitationOnlyFlow
                    validDomains={validDomains}
                    restrictDomains={restrictDomains}
                    isManagerNotAdmin={isManagerNotAdmin!}
                />
            ) : (
                <OpenInvitationFlow inviteLink={inviteLink} />
            )}
        </Dialog>
    );
};
