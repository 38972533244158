import { createStyles, makeStyles } from '@mui/styles';

import { ArrowLeft } from 'react-feather';
import { Button } from '@cognassist/react-components';
import NavBar from '../../../components/assessment-report/nav-bar';
import imgWarning from '../../../assets/img/warning.png';

const useStyles = makeStyles(() =>
    createStyles({
        wrapper: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexFlow: 'column nowrap',
            marginTop: '20vh',
            textAlign: 'center',
            '& h1': {
                marginTop: 24,
                textalign: 'center',
            },
            '& p': {
                marginTop: 24,
                textalign: 'center',
            },
        },
    })
);

export const GeneralError: React.FC = () => {
    const classes = useStyles();
    return (
        <div>
            <NavBar blank={true} />
            <div className={classes.wrapper}>
                <img src={imgWarning} alt='Triangle with exclaimation point' />
                <h1>Something went wrong</h1>
                <p>Sorry something has gone wrong. Please try again later.</p>
                <Button
                    sx={{ mt: 4 }}
                    size='large'
                    text='Go back'
                    startIcon={<ArrowLeft />}
                    onClick={() => history.back()}
                />
            </div>
        </div>
    );
};

export default GeneralError;
