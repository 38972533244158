import { Typography, alpha, styled } from '@mui/material';

import { Button } from '@cognassist/react-components';
import { pxToRem } from '../../../../utils/style-functions';
import { useStartAssessment } from '../../../../custom-hooks/useStartAssessment';

const AssessmentCardWrapper = styled('div')(({ theme }) => ({
    background: alpha(theme.palette.primary.main, 0.1),
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.primary.main}`,
    padding: theme.spacing(3),
    boxShadow: theme.cogShadows.lg,
}));

const CardTitle = styled(Typography)(({ theme }) => ({
    fontSize: pxToRem(20),
    lineHeight: 1.5,
    fontWeight: 500,
    marginBottom: theme.spacing(2),
}));

interface IOwnProps {
    hasStartedAssessment: boolean;
    firstName: string | null;
}

export const AssessmentCard: React.FC<IOwnProps> = ({
    hasStartedAssessment,
    firstName,
}) => {
    const { handleStartAssessment } = useStartAssessment(hasStartedAssessment);

    return (
        <AssessmentCardWrapper>
            <CardTitle variant='h3'>
                Looks like you haven&apos;t taken your assessment.
            </CardTitle>
            <Typography>
                It looks like you haven&apos;t completed your own cognitive
                assessment. Take this now to find out how you process
                information compared to {firstName ?? 'them'}.
            </Typography>
            <Button
                text='Take assessment'
                sx={{ mt: 3 }}
                onClick={handleStartAssessment}
            />
        </AssessmentCardWrapper>
    );
};
