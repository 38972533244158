import { Button, Dialog } from '@cognassist/react-components';
import {
    EnglishFirstLanguage,
    EthnicGroup,
    Gender,
    HasFormerNeurodiversityDiagnosis,
    IGetLearnerResponse,
    JobSeniorityLevel,
    NeurodivergenceType,
    RemoteKeys,
    WorkEnvironment,
} from '../../../../types';
import { FormFields, Tooltips } from '../../../styled-components';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Typography, styled } from '@mui/material';
import { getDob, parseDob } from '../../../../utils/dobFunctions';

import { DateOfBirth } from '../../pre-assessment/fields/DateOfBirth';
import { DiagnosedNeurodiversitySelect } from '../../pre-assessment/fields/DiagnosedNeurodiversitySelect';
import { EthnicGroupSelect } from '../../pre-assessment/additional-info/fields/EthnicGroupSelect';
import { FirstLanguageRadio } from '../../pre-assessment/additional-info/fields/FirstLanguageRadio';
import { FirstName } from './fields/FirstName';
import { Gender as GenderSelect } from '../../pre-assessment/additional-info/fields/Gender';
import { InfoBox } from '../../pre-assessment/InfoBox';
import { JobRole } from './fields/JobRole';
import { JobSeniorityLevelSelect } from './fields/JobSeniorityLevelSelect';
import { LastName } from './fields/LastName';
import { NeurodiversitiesSelect } from '../../pre-assessment/fields/NeurodiversitiesSelect';
import { OtherNeurodivergenceTypeDetails } from '../../pre-assessment/fields/OtherNeurodivergenceTypeDetails';
import { ProfileProvider } from '../../pre-assessment/ProfileProvider';
import { WorkEnvironmentSelect } from './fields/WorkEnvironmentSelect';
import capitaliseFirstLetter from '../../../../utils/transformFirstLetter';
import { endpoints } from '../../../../api/endpoints';
import { getInitialsFromNames } from '../../../../utils/getInitialsFromNames';
import { parseEnglishSecondLanguage } from '../../../../utils/parseEnglishSecondLanguage';
import { shouldForwardProps } from '../../../../utils/shouldForwardProp';
import { useAreNeurodiversityQuestionsEnabled } from '../../../../custom-hooks/useAreNeurodiversityQuestionsEnabled';
import { useAuth } from '../../../../custom-providers/AuthProvider';
import { useNavigate } from 'react-router-dom';
import { useRemoteValidation } from '../../../../custom-hooks/useRemoteValidation';
import { useSnackbar } from 'notistack';
import { useState } from 'react';

const PageWrapper = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'space-between',
}));

const FormWrapper = styled('div', {
    ...shouldForwardProps('isSmall'),
})<{ isSmall: boolean }>(({ isSmall, theme }) => ({
    marginBottom: isSmall ? theme.spacing(4) : theme.spacing(15),
}));

const RequiredMessage = styled('p', {
    ...shouldForwardProps('hasError'),
})<{ hasError?: boolean }>(({ hasError, theme }) => ({
    fontWeight: 600,
    marginBottom: theme.spacing(3),
    color: hasError ? theme.palette.error.main : 'inherit',
}));

const ButtonWrapper = styled('div', {
    ...shouldForwardProps('isSmall'),
})<{ isSmall: boolean }>(({ isSmall, theme }) => ({
    margin: theme.spacing(3, 0),
    display: 'flex',
    justifyContent: isSmall ? 'space-around' : 'flex-end',
    gap: theme.spacing(3),
    '& button': {
        flexGrow: isSmall ? 1 : 0,
    },
}));

const userProfileValidationMap = {
    jobRole: 'JobRole',
    jobSeniorityLevel: 'JobSeniorityLevel',
    workEnvironment: 'WorkEnvironment',
} as const;

type UserProfileRemoteKeys = RemoteKeys<typeof userProfileValidationMap>;

export interface IFormFields {
    firstName: string;
    lastName: string;
    gender: Gender | '';
    dateOfBirth: Date | null;
    englishFirstLanguage: EnglishFirstLanguage;
    ethnicGroup: EthnicGroup | '';
    hasFormerNeurodiversityDiagnosis: HasFormerNeurodiversityDiagnosis;
    neurodivergenceTypes: NeurodivergenceType[];
    otherNeurodivergenceTypeDetails: string | null;
    jobRole: string | null;
    jobSeniorityLevel: JobSeniorityLevel | '';
    workEnvironment: WorkEnvironment | '';
}

interface IOwnProps {
    learnerDetails: IGetLearnerResponse;
    isSmall: boolean;
}

export const PersonalDetails: React.FC<IOwnProps> = ({
    learnerDetails,
    isSmall,
}) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const [showDialog, setShowDialog] = useState<boolean>(false);

    const parseAPIErrors = useRemoteValidation<typeof userProfileValidationMap>(
        userProfileValidationMap
    );

    const {
        state: {
            userConfig: { userId },
            clientConfig: { clientId },
        },
        dispatch,
    } = useAuth();

    const { putLearnerDetails } = endpoints();

    const { enqueueSnackbar } = useSnackbar();

    const showNeurodiversityQuestions = useAreNeurodiversityQuestionsEnabled();

    const gender =
        learnerDetails.gender === Gender.None ? '' : learnerDetails.gender;

    const ethnicGroup = learnerDetails.ethnicGroup ?? '';

    const jobSeniorityLevel = learnerDetails.jobSeniorityLevel ?? '';

    const workEnvironment = learnerDetails.workEnvironment ?? '';

    const methods = useForm<IFormFields>({
        defaultValues: {
            firstName: learnerDetails.firstName,
            lastName: learnerDetails.lastName,
            hasFormerNeurodiversityDiagnosis:
                learnerDetails.hasFormerNeurodiversityDiagnosis,
            neurodivergenceTypes: learnerDetails.neurodivergenceTypes,
            otherNeurodivergenceTypeDetails:
                learnerDetails.otherNeurodivergenceTypeDetails,
            gender: gender,
            dateOfBirth: learnerDetails.dateOfBirth
                ? getDob(learnerDetails.dateOfBirth)
                : null,
            englishFirstLanguage: parseEnglishSecondLanguage(
                learnerDetails.hasEnglishAsASecondLanguage
            ),
            ethnicGroup: ethnicGroup,
            jobRole: learnerDetails.jobRole,
            jobSeniorityLevel: jobSeniorityLevel,
            workEnvironment: workEnvironment,
        },
    });

    const {
        handleSubmit,
        formState: { errors, isDirty },
    } = methods;

    const goToDashboard = () => navigate('/dashboard');

    const getPatchRequestBody = (formData: IFormFields) => {
        return {
            firstName: formData.firstName,
            lastName: formData.lastName,
            gender: formData.gender === '' ? Gender.None : formData.gender,
            dateOfBirth: formData.dateOfBirth
                ? parseDob(new Date(formData.dateOfBirth))
                : null,
            hasEnglishAsASecondLanguage:
                formData.englishFirstLanguage !== 'yes',
            ethnicGroup:
                formData.ethnicGroup === '' ? null : formData.ethnicGroup,
            hasFormerNeurodiversityDiagnosis:
                formData.hasFormerNeurodiversityDiagnosis ?? null,
            neurodivergenceTypes: formData.neurodivergenceTypes ?? null,
            otherNeurodivergenceTypeDetails:
                formData.otherNeurodivergenceTypeDetails ?? null,
            jobRole: formData.jobRole ?? null,
            jobSeniorityLevel:
                formData.jobSeniorityLevel === ''
                    ? null
                    : formData.jobSeniorityLevel,
            workEnvironment:
                formData.workEnvironment === ''
                    ? null
                    : formData.workEnvironment,
        };
    };

    const handleSave: SubmitHandler<IFormFields> = async (formData) => {
        if (!userId || !clientId) {
            return;
        }

        setLoading(true);

        const requestBody = getPatchRequestBody(formData);

        const { error } = await putLearnerDetails<UserProfileRemoteKeys>({
            learnerUserId: userId,
            data: requestBody,
        });

        if (error) {
            const errors = parseAPIErrors(error);

            if (errors.length) {
                errors.forEach((error) => {
                    enqueueSnackbar(error.message, { variant: 'error' });
                });
            } else {
                enqueueSnackbar('Something went wrong', {
                    variant: 'error',
                });
            }
        } else {
            dispatch({
                type: 'SET_USER_CONFIG',
                payload: {
                    name: `${capitaliseFirstLetter(
                        requestBody.firstName
                    )} ${capitaliseFirstLetter(requestBody.lastName)}`,
                    initials: getInitialsFromNames(
                        requestBody.firstName,
                        requestBody.lastName
                    ),
                },
            });

            enqueueSnackbar('Your details have been updated', {
                variant: 'success',
            });
        }
        setLoading(false);
    };

    const save = async () => {
        handleSubmit(handleSave)();
    };

    const cancelHandler = () => {
        if (isDirty) {
            setShowDialog(true);
        } else {
            goToDashboard();
        }
    };

    const hasRequiredError =
        !!errors.dateOfBirth ||
        !!errors.hasFormerNeurodiversityDiagnosis ||
        !!errors.neurodivergenceTypes ||
        !!errors.firstName ||
        !!errors.lastName;

    return (
        <ProfileProvider negativeTopOffset={220}>
            <PageWrapper>
                <FormFields>
                    <FormProvider {...methods}>
                        <FormWrapper isSmall={isSmall}>
                            <Typography variant='h2' sx={{ mb: 4 }}>
                                Change your basic info
                            </Typography>
                            {isSmall && (
                                <RequiredMessage hasError={hasRequiredError}>
                                    Fields marked with an asterisk (*) are
                                    required
                                </RequiredMessage>
                            )}
                            <FirstName isSmall={isSmall} />
                            <LastName isSmall={isSmall} />

                            {learnerDetails.hasTakenAssessment && (
                                <>
                                    <JobRole isSmall={isSmall} />
                                    <JobSeniorityLevelSelect
                                        defaultValue={jobSeniorityLevel}
                                        isSmall={isSmall}
                                    />
                                    <WorkEnvironmentSelect
                                        defaultValue={workEnvironment}
                                        isSmall={isSmall}
                                    />
                                    <DateOfBirth isSmall={isSmall} />
                                    {showNeurodiversityQuestions && (
                                        <>
                                            <DiagnosedNeurodiversitySelect
                                                defaultValue={
                                                    learnerDetails.hasFormerNeurodiversityDiagnosis
                                                }
                                                isSmall={isSmall}
                                            />
                                            <NeurodiversitiesSelect
                                                isSmall={isSmall}
                                            />
                                            <OtherNeurodivergenceTypeDetails />
                                        </>
                                    )}
                                    <FirstLanguageRadio isSmall={isSmall} />
                                    <GenderSelect
                                        defaultValue={gender}
                                        isSmall={isSmall}
                                    />
                                    <EthnicGroupSelect
                                        defaultValue={ethnicGroup}
                                        isSmall={isSmall}
                                    />
                                </>
                            )}
                            <ButtonWrapper isSmall={isSmall}>
                                <Button
                                    color='inherit'
                                    onClick={cancelHandler}
                                    text='Cancel'
                                />
                                <Button
                                    onClick={save}
                                    text='Save changes'
                                    loading={loading}
                                />
                            </ButtonWrapper>
                        </FormWrapper>
                    </FormProvider>
                </FormFields>
                <Tooltips>
                    <InfoBox isSmall={isSmall} />
                </Tooltips>
            </PageWrapper>
            <Dialog
                open={showDialog}
                title='Are you sure you want to return to the previous page?'
                content={<></>}
                confirmButtonProps={{
                    text: 'Yes',
                    onClick: goToDashboard,
                }}
                cancelButtonProps={{
                    color: 'inherit',
                    text: 'No',
                    onClick: () => setShowDialog(false),
                }}
            />
        </ProfileProvider>
    );
};
