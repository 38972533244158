import { AssessmentStatus, IUser, IUsersResponse, Roles } from '../../../types';
import {
    Badge,
    CircularProgress,
    Chip as MuiChip,
    Pagination,
    SelectChangeEvent,
    Typography,
    styled,
    useTheme,
} from '@mui/material';
import { Button, Input, Table } from '@cognassist/react-components';
import { Filter, Plus, User, X, XCircle } from 'react-feather';
import {
    IBulkConfigUser,
    useUsers,
} from '../../../custom-providers/UsersProvider';
import { useEffect, useRef } from 'react';

import { Action } from '../../../state/table-state';
import { UserRowContextMenu } from './UserRowContextMenu';
import { assessmentStatusLookup } from '../../../utils/assessmentStatusLookup';
import { useAuth } from '../../../custom-providers/AuthProvider';
import { useNavigate } from 'react-router-dom';
import useResizeObserver from 'use-resize-observer';
import { useUserFns } from '../../../custom-hooks/useUserFns';

const SearchWrapper = styled('div')(({ theme }) => ({
    [theme.breakpoints.down(520)]: {
        flex: '1 1 100%',
    },
}));

const SearchInput = styled(Input)(({ theme }) => ({
    minWidth: 270,
    backgroundColor: theme.palette.common.white,
    '&.Mui-focused': {
        marginRight: '2px',
    },
    [theme.breakpoints.down(520)]: {
        minWidth: '100%',
    },
}));

const TdUserName = styled('div')(({ theme }) => ({
    color: theme.palette.primary.main,
    fontWeight: 600,
    cursor: 'pointer',
    '&:hover': {
        textDecoration: 'underline',
        textDecorationColor: theme.palette.primary.main,
    },
}));

const PaginationWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(3),
}));

const UserEmail = styled('span')(() => ({
    maxWidth: 200,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    display: 'block',
    textOverflow: 'ellipsis',
}));

const FilterButton = styled(Button)(({ theme }) => ({
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down(744)]: {
        width: '100%',
        margin: theme.spacing(1, 0),
    },
}));

const TagWrapper = styled('div')(({ theme }) => ({
    margin: theme.spacing(2, 0, 0),
    order: 2,
    width: '100%',
}));

const Chip = styled(MuiChip)(({ theme }) => ({
    margin: theme.spacing(0, 1, 1, 0),
    border: `1px solid ${theme.palette.grey[200]}`,
    color: theme.palette.text.primary,
    boxShadow: theme.cogShadows.sm,
}));

const ClearFiltersButton = styled(Button)(({ theme }) => ({
    margin: theme.spacing(0, 1, 1, 0),
}));

const CloseIcon = styled(X)(({ theme }) => ({
    color: theme.palette.text.primary,
    cursor: 'pointer',
}));

const EndRowWrapper = styled('span')(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
}));

const LoadingWrapper = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
}));

const NoDataWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexFlow: 'column nowrap',
    minHeight: 300,
    border: `1px solid ${theme.palette.grey[100]}`,
    background: 'white',
    top: -8,
    position: 'relative',
    boxShadow: theme.cogShadows.base,
    borderRadius: '0 0 8px 8px',
}));

interface IOwnProps {
    usersData?: IUsersResponse;
    totalRecords: number;
    totalPages: number;
    currentPageRecords: number;
    pageSize: number;
    searchText: string;
    currentPage: number;
    loading: boolean;
    isMyTeam: boolean;
    setPageSize: React.Dispatch<React.SetStateAction<number>>;
    setSearchText: React.Dispatch<React.SetStateAction<string>>;
    setActiveUserId: React.Dispatch<React.SetStateAction<string | undefined>>;
    tableDispatch: (value: Action) => void;
    setBulkUsers: React.Dispatch<
        React.SetStateAction<IBulkConfigUser[] | null>
    >;
    setBulkTotal: React.Dispatch<React.SetStateAction<number | null>>;
}

export const UsersTable: React.FC<IOwnProps> = ({
    usersData,
    totalRecords,
    totalPages,
    currentPageRecords,
    pageSize,
    searchText,
    currentPage,
    loading,
    isMyTeam,
    setPageSize,
    setSearchText,
    setActiveUserId,
    tableDispatch,
    setBulkUsers,
    setBulkTotal,
}) => {
    const theme = useTheme();
    const navigate = useNavigate();

    const { handleDeleteUserDialog } = useUserFns();

    const {
        state: {
            userConfig: { userId, roles },
        },
    } = useAuth();

    const {
        dispatch,
        state: { activeFilters },
    } = useUsers();

    const searchRef = useRef<HTMLInputElement | null>(null);

    const { ref: wrapperRef, width: tableWidth = 1 } =
        useResizeObserver<HTMLDivElement>();

    useEffect(() => {
        if (searchRef.current) {
            const element = searchRef.current;
            element.focus();
        }
    }, [searchRef.current]);

    const updatePageNumber = (
        _event: React.ChangeEvent<unknown>,
        value: number
    ) => {
        tableDispatch({
            type: 'UPDATE_CURRENT_PAGE',
            payload: { pageNumber: value },
        });
    };

    const setRecordsPerPage = (evt: SelectChangeEvent<unknown>) => {
        setPageSize(parseInt(evt.target.value as string));
        localStorage.setItem('usersTablePageSize', evt.target.value as string);
        tableDispatch({
            type: 'UPDATE_CURRENT_PAGE',
            payload: { pageNumber: 1 },
        });
    };

    const parseRoles = (roles: string[]) => {
        if (roles.length === 1) {
            return roles.join(', ').replace('Learner', '-');
        } else if (roles.includes('Learner')) {
            return roles.filter((role) => role !== 'Learner').join(', ');
        } else {
            return roles.join(', ');
        }
    };

    const getVisibleRows = () => {
        if (totalRecords < pageSize) {
            return totalRecords;
        }

        if (currentPageRecords < pageSize) {
            return usersData?.users.length ?? 0;
        }

        return pageSize;
    };

    const launchEditUser = (userId: string) => {
        setActiveUserId(userId);
    };

    const bulkUpdateManagerHandler = (
        rows: IBulkConfigUser[],
        bulkTotal: number | null
    ) => {
        if (rows) {
            setBulkUsers(rows);
            setBulkTotal(bulkTotal ?? null);
        } else {
            setBulkUsers(null);
            setBulkTotal(null);
        }
        dispatch({
            type: 'SET_DIALOG_MANAGEMENT',
            payload: {
                key: 'bulkUpdateManager',
            },
        });
    };

    const bulkUpdateSecondaryManagerHandler = (
        rows: IBulkConfigUser[],
        bulkTotal: number | null
    ) => {
        if (rows) {
            setBulkUsers(rows);
            setBulkTotal(bulkTotal ?? null);
        } else {
            setBulkUsers(null);
            setBulkTotal(null);
        }
        dispatch({
            type: 'SET_DIALOG_MANAGEMENT',
            payload: {
                key: 'bulkUpdateSecondaryManager',
            },
        });
    };

    const getTableHeadings = (tableWidth?: number) => {
        const headings = ['Name', 'Email', 'User role(s)', 'Assessment status'];

        if (tableWidth && tableWidth < 400) {
            headings.push('Actions');
        }

        return headings;
    };

    const handleNameClick = (user: IUser) => {
        if (isMyTeam) {
            if (user.assessmentStatus === AssessmentStatus.Complete) {
                navigate(`/users/${user.id}/profile`);
            } else {
                launchEditUser(user.id);
            }
        } else {
            launchEditUser(user.id);
        }
    };

    const contextMenu = (user: IUser) => {
        return (
            <UserRowContextMenu
                editUser={() => launchEditUser(user.id)}
                deleteUser={() => handleDeleteUserDialog(user)}
                rowIsCurrentUser={userId === user.id}
                userId={user.id}
                showProfile={
                    isMyTeam &&
                    user.assessmentStatus === AssessmentStatus.Complete
                }
            />
        );
    };

    const getRowData = (user: IUser, tableWidth?: number) => {
        const rowData = [
            <TdUserName onClick={() => handleNameClick(user)} key={user.id}>
                {user.firstName} {user.lastName}{' '}
                {user.championStatus === 10 && (
                    <Badge variant='dot' color='primary' sx={{ ml: 1 }} />
                )}
            </TdUserName>,
            <UserEmail key={user.id} title={user.email}>
                {user.email}
            </UserEmail>,
            parseRoles(user.roles),
            <EndRowWrapper key={user.id}>
                {assessmentStatusLookup(user.assessmentStatus)}
                {tableWidth && tableWidth > 399 && contextMenu(user)}
            </EndRowWrapper>,
        ];

        if (tableWidth && tableWidth < 400) {
            rowData.push(contextMenu(user));
        }

        return rowData;
    };

    if (loading) {
        return (
            <LoadingWrapper>
                <CircularProgress />
            </LoadingWrapper>
        );
    }

    if (!usersData) {
        return null;
    }

    return (
        <div ref={wrapperRef}>
            <div>
                <Table
                    ariaTableLabel='Users table'
                    showTableInfo={true}
                    showRecordsPerPage={true}
                    visibleRows={getVisibleRows()}
                    totalRows={totalRecords}
                    rowsPerPage={pageSize}
                    setRowsPerPage={setRecordsPerPage}
                    tableHeadings={getTableHeadings(tableWidth)}
                    rows={usersData.users.map((user) => ({
                        id: user.id,
                        rowData: getRowData(user, tableWidth),
                    }))}
                    isDraggable={false}
                    allowBulkUpdate={
                        roles?.includes(Roles.SysAdmin) ||
                        roles?.includes(Roles.ClientAdmin)
                    }
                    bulkActions={[
                        {
                            text: 'Edit manager',
                            startIcon: <User />,
                            handler: bulkUpdateManagerHandler,
                        },
                        {
                            text: 'Edit secondary manager(s)',
                            startIcon: <User />,
                            handler: bulkUpdateSecondaryManagerHandler,
                        },
                    ]}
                    showSelectAllPrompt={totalRecords > getVisibleRows()}
                >
                    <>
                        <SearchWrapper>
                            <label
                                id='SearchLabel'
                                htmlFor='SearchInput'
                                className='visually-hidden'
                            >
                                Search for users
                            </label>
                            <SearchInput
                                inputRef={(e: HTMLInputElement) => {
                                    searchRef.current = e;
                                }}
                                inputProps={{
                                    id: 'SearchInput',
                                    'aria-labelledby': 'SearchLabel',
                                }}
                                size='small'
                                placeholder='Who are you looking for?'
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                                aria-label='Search users'
                                title='Search for users'
                                endAdornment={
                                    searchText && (
                                        <XCircle
                                            color={theme.palette.grey[500]}
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => setSearchText('')}
                                        />
                                    )
                                }
                            />
                            <FilterButton
                                text='Filter table'
                                startIcon={<Filter size={18} />}
                                color='secondary'
                                onClick={() =>
                                    dispatch({
                                        type: 'SET_DIALOG_MANAGEMENT',
                                        payload: {
                                            key: 'filterDrawer',
                                            value: true,
                                        },
                                    })
                                }
                            />
                        </SearchWrapper>
                        {!!activeFilters.length && (
                            <TagWrapper>
                                {activeFilters.map((val) => (
                                    <Chip
                                        key={val.tagName}
                                        variant='outlined'
                                        label={val.tagName}
                                        icon={
                                            <CloseIcon
                                                size={18}
                                                onClick={() => {
                                                    dispatch({
                                                        type: 'REMOVE_ACTIVE_FILTER',
                                                        payload: val,
                                                    });
                                                }}
                                            />
                                        }
                                    />
                                ))}
                                <ClearFiltersButton
                                    text='Clear all'
                                    variant='text'
                                    size='small'
                                    color='inherit'
                                    onClick={() =>
                                        dispatch({
                                            type: 'CLEAR_ACTIVE_FILTERS',
                                        })
                                    }
                                />
                            </TagWrapper>
                        )}
                    </>
                </Table>
            </div>

            {!usersData.users.length && (
                <NoDataWrapper>
                    <Typography variant='h1' sx={{ fontWeight: 400 }}>
                        No users available
                    </Typography>
                    <Typography>
                        Please adjust filter or invite users
                    </Typography>
                    <Button
                        text='Invite users'
                        startIcon={<Plus />}
                        sx={{ mt: 2 }}
                        onClick={() =>
                            dispatch({
                                type: 'SET_DIALOG_MANAGEMENT',
                                payload: {
                                    key: 'inviteUsers',
                                    value: true,
                                },
                            })
                        }
                    />
                </NoDataWrapper>
            )}

            {totalPages > 1 && (
                <PaginationWrapper>
                    <Pagination
                        page={currentPage}
                        onChange={updatePageNumber}
                        count={totalPages}
                        boundaryCount={6}
                    />
                </PaginationWrapper>
            )}
        </div>
    );
};
