import { CogTypeLookup, IScreenSize } from '../../../../types';
import React, { useRef, useState } from 'react';
import { Typography, styled } from '@mui/material';

import { AssessmentCard } from './AssessmentCard';
import { Button } from '@cognassist/react-components';
import { CogTypeBanner } from '../../cognitive-map-v2/overview/CogTypeBanner';
import { CurrentTab } from '../../../../pages/authenticated/users/[id]/profile';
import ProcessingInformationVisual from '../../cognitive-map-v2/overview/ProcessingInformationVisual';
import { getScreenSizeFromWidth } from '../../cognitive-map-v2/helpers';
import { pxToRem } from '../../../../utils/style-functions';
import { useAuth } from '../../../../custom-providers/AuthProvider';
import useResizeObserver from '@react-hook/resize-observer';
import { useUsers } from '../../../../custom-providers/UsersProvider';

const Wrapper = styled('div')(() => ({}));

const ProcessingInformationVisualWrapper = styled('div')(({ theme }) => ({
    background: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    border: `1px solid #EDEDEF`,
}));

const InnerWrapper = styled('div')(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: '2fr 1fr',
    gap: theme.spacing(3),
    [theme.breakpoints.down(990)]: {
        gridTemplateColumns: '1fr',
    },
}));

const CardsWrapper = styled('div')(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: theme.spacing(3),
}));

const CommunicateCard = styled('div')(({ theme }) => ({
    background: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    border: `1px solid #EDEDEF`,
    padding: theme.spacing(3),
}));

const CardTitle = styled(Typography)(({ theme }) => ({
    fontSize: pxToRem(20),
    lineHeight: 1.5,
    fontWeight: 500,
    marginBottom: theme.spacing(2),
}));

interface IOwnProps {
    setTab: React.Dispatch<React.SetStateAction<CurrentTab>>;
}

export const Overview: React.FC<IOwnProps> = ({ setTab }) => {
    const {
        state: { teamInsights, enterpriseUser },
    } = useUsers();

    const {
        state: {
            userConfig: { hasTakenAssessment, hasStartedAssessment },
        },
    } = useAuth();

    const [screenSize, setScreenSize] = useState<IScreenSize>({
        isMobile: false,
        isTablet: false,
    });

    const wrapperRef = useRef<HTMLDivElement>(null);

    useResizeObserver(wrapperRef, (entry) => {
        const { width } = entry.contentRect;
        setScreenSize(getScreenSizeFromWidth(width));
    });

    if (!teamInsights || !enterpriseUser) {
        return null;
    }

    const { learner, manager } = teamInsights;

    if (!learner) {
        return null;
    }

    const fullName = `${enterpriseUser.firstName} ${enterpriseUser.lastName}`;

    const { cogTypeContent } = learner;

    return (
        <Wrapper ref={wrapperRef}>
            <CogTypeBanner
                cogTypeId={learner.cogType}
                cogTypeName={
                    learner.cogType ? CogTypeLookup[learner.cogType] : null
                }
                cogTypeDescription={cogTypeContent?.managerDescription ?? null}
                showCopy={false}
                fullName={fullName}
                screenSize={screenSize}
                isManagerViewing={true}
            />
            <Typography variant='h2' sx={{ mb: 3 }}>
                How {enterpriseUser.firstName} processes information
            </Typography>
            <InnerWrapper>
                <div>
                    <ProcessingInformationVisualWrapper>
                        <ProcessingInformationVisual
                            bars={[
                                {
                                    id: 1,
                                    leftHeading: 'Words',
                                    rightHeading: 'Imagery',
                                    employeeScore:
                                        learner.neurodiversitySummary
                                            ?.processingBiasZ ?? null,
                                    userScore:
                                        manager?.neurodiversitySummary
                                            ?.processingBiasZ ?? null,
                                },
                                {
                                    id: 2,
                                    leftHeading: 'Long sessions',
                                    rightHeading: 'Short sessions',
                                    employeeScore:
                                        learner.neurodiversitySummary
                                            ?.memoryIndexAdjustedZ ?? null,
                                    userScore:
                                        manager?.neurodiversitySummary
                                            ?.memoryIndexAdjustedZ ?? null,
                                },
                            ]}
                            viewingAsManager={true}
                            employeeName={enterpriseUser.firstName ?? ''}
                        />
                    </ProcessingInformationVisualWrapper>
                </div>
                <CardsWrapper>
                    <CommunicateCard>
                        <CardTitle variant='h3'>
                            Discover tips on how to best communicate with{' '}
                            {enterpriseUser.firstName ?? 'them'}.
                        </CardTitle>
                        <Typography>
                            We’ve created a list of simple tips and tricks that
                            can help you to communicate effectively with{' '}
                            {enterpriseUser.firstName ?? 'them'} in various
                            settings.
                        </Typography>
                        <Button
                            text='View communication tips'
                            sx={{ mt: 3 }}
                            onClick={() => setTab(CurrentTab.Communication)}
                        />
                    </CommunicateCard>
                    {!hasTakenAssessment &&
                        hasStartedAssessment !== undefined && (
                            <AssessmentCard
                                hasStartedAssessment={hasStartedAssessment}
                                firstName={enterpriseUser.firstName}
                            />
                        )}
                </CardsWrapper>
            </InnerWrapper>
        </Wrapper>
    );
};
