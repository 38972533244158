import { FormControl, MenuItem, Select } from '@mui/material';

import { CogInput } from '../../../../styled-components';
import { Field } from '../../../pre-assessment/Field';
import { JobSeniorityLevel } from '../../../../../types';
import { useFormContext } from 'react-hook-form';

const options = [
    { label: 'Trainee', value: JobSeniorityLevel.Trainee },
    { label: 'Junior', value: JobSeniorityLevel.Junior },
    { label: 'Mid-level', value: JobSeniorityLevel.MidLevel },
    { label: 'Senior', value: JobSeniorityLevel.Senior },
    { label: 'Manager', value: JobSeniorityLevel.Manager },
    { label: 'Director', value: JobSeniorityLevel.Director },
    { label: 'Vice President', value: JobSeniorityLevel.VicePresident },
    { label: 'Chief X Officer', value: JobSeniorityLevel.ChiefOfficer },
    { label: 'Partner', value: JobSeniorityLevel.Partner },
    { label: 'Owner', value: JobSeniorityLevel.Owner },
];

interface IOwnProps {
    defaultValue: JobSeniorityLevel | '';
    isSmall?: boolean;
}

export const JobSeniorityLevelSelect: React.FC<IOwnProps> = ({
    defaultValue,
    isSmall,
}) => {
    const {
        formState: { errors },
        register,
    } = useFormContext<{ jobSeniorityLevel: JobSeniorityLevel | '' }>();

    const validate = (v: JobSeniorityLevel | '') =>
        ['', 10, 20, 30, 40, 50, 60, 70, 80, 90, 100].includes(v);

    return (
        <Field
            label='Job seniority level'
            labelId='seniorityLabel'
            hasError={!!errors.jobSeniorityLevel}
            isSmall={isSmall}
        >
            <FormControl fullWidth>
                <Select
                    {...register('jobSeniorityLevel', {
                        validate: validate,
                    })}
                    defaultValue={defaultValue}
                    labelId='seniorityLabel'
                    input={
                        <CogInput
                            inputProps={{
                                'aria-labelledby': 'seniorityLabel',
                            }}
                            hasError={!!errors.jobSeniorityLevel}
                        />
                    }
                >
                    {options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Field>
    );
};
