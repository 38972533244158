import ProcessingInformationBar from './ProcessingInformationBar';
import { pxToRem } from '../../../../utils/style-functions';
import { styled } from '@mui/material';

const VisualWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(3),
    display: 'flex',
    flexFlow: 'row wrap',
    gap: theme.spacing(3),
}));

const IndexWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexFlow: 'row wrap',
    flexBasis: '100%',
    gap: theme.spacing(3),
}));

const LabelWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    flexBasis: '100%',
    gap: theme.spacing(1),
}));

const Label = styled('p')(({ theme }) => ({
    background: '#F6F7FB',
    padding: theme.spacing(0.75),
    fontSize: pxToRem(18),
    fontWeight: 500,
    borderRadius: theme.shape.borderRadius,
    textAlign: 'center',
}));

interface IBarContent {
    id: number;
    leftHeading: string;
    rightHeading: string;
    userScore: number | null;
    employeeScore?: number | null;
}

interface IOwnProps {
    bars: IBarContent[];
    viewingAsManager?: boolean;
    employeeName?: string;
}

const ProcessingInformationVisual: React.FC<IOwnProps> = ({
    bars,
    viewingAsManager = false,
    employeeName,
}) => {
    return (
        <VisualWrapper id='graph-container'>
            {bars.map((bar) => (
                <IndexWrapper key={bar.id}>
                    <LabelWrapper>
                        <Label>{bar.leftHeading}</Label>
                        <Label>{bar.rightHeading}</Label>
                    </LabelWrapper>
                    <ProcessingInformationBar
                        userScore={bar.userScore}
                        employeeScore={bar.employeeScore}
                        viewingAsManager={viewingAsManager}
                        employeeName={employeeName}
                    />
                </IndexWrapper>
            ))}
        </VisualWrapper>
    );
};

export default ProcessingInformationVisual;
