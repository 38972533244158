import { Input as CogInput } from '@cognassist/react-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputProps } from '@cognassist/react-components/dist/types/components/Input/Input.types';
import React from 'react';
import { byPrefixAndName } from '@awesome.me/kit-5519c33536/icons';
import { shouldForwardProps } from '../../../utils/shouldForwardProp';
import { styled } from '@mui/material';

const Input = styled(CogInput, {
    ...shouldForwardProps('isMobile'),
})<{ isMobile?: boolean }>(({ isMobile, theme }) => ({
    minHeight: isMobile ? 52 : 72,
    background: theme.palette.grey[50],
    padding: isMobile ? theme.spacing(1) : theme.spacing(3),
    '& input::placeholder': {
        color: theme.palette.grey[800],
        opacity: 1,
    },
    '& textarea::placeholder': {
        color: theme.palette.grey[800],
        opacity: 1,
    },
}));

const Icon = styled(FontAwesomeIcon)(({ theme }) => ({
    background: theme.palette.common.white,
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    cursor: 'pointer',
    marginLeft: theme.spacing(0.5),
}));

interface IOwnProps extends InputProps {
    onSubmit: () => void;
    isMobile?: boolean;
}

export const Textbox: React.FC<IOwnProps> = ({
    onSubmit,
    isMobile,
    ...props
}) => {
    return (
        <Input
            {...props}
            endAdornment={
                <Icon
                    icon={byPrefixAndName.far['arrow-turn-down-left']}
                    onClick={onSubmit}
                />
            }
            isMobile={isMobile}
            onKeyDown={(e) => {
                if (e.key === 'Enter') {
                    e.preventDefault();
                    onSubmit();
                }
            }}
        />
    );
};
