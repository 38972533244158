import { Link, useSearchParams } from 'react-router-dom';
import { Avatar as MuiAvatar, Typography, alpha, styled } from '@mui/material';

import { pxToRem } from '../../../utils/style-functions';
import { shouldForwardProps } from '../../../utils/shouldForwardProp';
import { useAuth } from '../../../custom-providers/AuthProvider';

const NavUser = styled(Link)(() => ({
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    width: '100%',
    border: 'none',
    background: 'none',
    textDecoration: 'none',
}));

const Avatar = styled(MuiAvatar, {
    ...shouldForwardProps('navExpand'),
})<{ navExpand: boolean }>(({ navExpand, theme }) => ({
    marginRight: navExpand ? '10px' : 0,
    height: 44,
    width: 44,
    background: alpha(theme.palette.primary.main, 0.25),
    color: theme.palette.text.primary,
    fontSize: pxToRem(18),
    lineHeight: pxToRem(18),
    fontWeight: 500,
    textTransform: 'uppercase',
}));

const UserName = styled(Typography)(({ theme }) => ({
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: theme.palette.text.primary,
    fontWeight: 600,
    width: 200,
}));

const AccSettings = styled(Typography)(({ theme }) => ({
    fontSize: pxToRem(14),
    lineHeight: pxToRem(21),
    color: theme.palette.text.primary,
}));

const Wrapper = styled('div')(({ theme }) => ({
    width: '100%',
    padding: theme.spacing(0, 3, 2),
}));

const NameWrapper = styled('div')(() => ({
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexFlow: 'column nowrap',
}));

interface IOwnProps {
    navExpand: boolean;
}

export const UserProfileMenuItem: React.FC<IOwnProps> = ({ navExpand }) => {
    const {
        state: {
            userConfig: { name, initials },
        },
    } = useAuth();
    const [searchParams] = useSearchParams({ returnurl: '' });
    return (
        <Wrapper id='profile'>
            <NavUser
                to={{
                    pathname: '/account-settings',
                    search: `?returnurl=${searchParams.get('returnurl')}`,
                }}
            >
                <Avatar navExpand={navExpand}>{initials}</Avatar>
                {navExpand && (
                    <NameWrapper>
                        <UserName>{name}</UserName>
                        <AccSettings>Account Settings</AccSettings>
                    </NameWrapper>
                )}
            </NavUser>
        </Wrapper>
    );
};
