import { useEffect, useState } from 'react';

import { IInsightsResponse } from '../../../types';
import PageHeader from '../../../components/PageHeader';
import PageNotFound from '../../PageNotFound';
import { PowerBIEmbed } from 'powerbi-client-react';
import { endpoints } from '../../../api/endpoints';
import { models } from 'powerbi-client';
import { styled } from '@mui/material';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { useIsAdmin } from '../../../custom-hooks/useIsAdmin';

const Wrapper = styled('div')(() => ({
    minHeight: '100vh',
}));

const InnerWrapper = styled('div')(() => ({
    display: 'flex',
    height: 'calc(100vh - 124px)',
    '& .reportClass': {
        minWidth: '100%',
        '& iframe': {
            border: 'none',
        },
    },
}));

export const Insights = () => {
    const powerBiEnabled = useFeatureIsOn('powerbi-test-dashboard');
    const isAdmin = useIsAdmin();

    const { getPowerBIAccessToken } = endpoints();

    const [powerBIDetails, setPowerBIDetails] = useState<IInsightsResponse>();

    useEffect(() => {
        getToken();
    }, []);

    const getToken = async () => {
        const { data } = await getPowerBIAccessToken(
            '23d2baf9-cf70-4ea1-9573-8a14f2bc0fbf'
        );

        if (data) {
            setPowerBIDetails(data);
        }
    };

    if (!powerBiEnabled || !isAdmin) {
        return <PageNotFound showTopNav={false} />;
    }

    if (!powerBIDetails) {
        return null;
    }

    return (
        <Wrapper>
            <PageHeader title='Insights' />
            <InnerWrapper>
                <PowerBIEmbed
                    embedConfig={{
                        type: 'report', // Supported types: report, dashboard, tile, visual, qna, paginated report and create
                        id: powerBIDetails.id,
                        embedUrl: powerBIDetails.embedUrl,
                        accessToken: powerBIDetails.token,
                        tokenType: models.TokenType.Embed, // Use models.TokenType.Aad for SaaS embed
                        settings: {
                            panes: {
                                filters: {
                                    expanded: false,
                                    visible: false,
                                },
                            },
                            background: models.BackgroundType.Transparent,
                        },
                    }}
                    eventHandlers={
                        new Map([
                            [
                                'loaded',
                                function () {
                                    console.log('Report loaded');
                                },
                            ],
                            [
                                'rendered',
                                function () {
                                    console.log('Report rendered');
                                },
                            ],
                            [
                                'error',
                                function (event) {
                                    console.log(event?.detail);
                                },
                            ],
                            [
                                'visualClicked',
                                () => console.log('visual clicked'),
                            ],
                            ['pageChanged', (event) => console.log(event)],
                        ])
                    }
                    cssClassName={'reportClass'}
                    getEmbeddedComponent={(embeddedReport) => {
                        console.log(embeddedReport);
                    }}
                />
            </InnerWrapper>
        </Wrapper>
    );
};
