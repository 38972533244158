import { CogTypeEnum, CogTypeLookup } from '../../../../../types';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import {
    Tab,
    TabRowsMaxWidth,
    Tabs,
    TabsRow,
} from '../../../../../components/styled-components/Tabs';
import { Typography, styled } from '@mui/material';

import { Communication } from '../../../../../components/main-app/users/profile/Communication';
import { MainContentInner } from '../../../../../components/styled-components/Wrappers';
import { ChevronRight as MuiChevronRight } from 'react-feather';
import { Overview } from '../../../../../components/main-app/users/profile/Overview';
import PageHeader from '../../../../../components/PageHeader';
import { endpoints } from '../../../../../api/endpoints';
import { pxToRem } from '../../../../../utils/style-functions';
import { useParams } from 'react-router-dom';
import { useUsers } from '../../../../../custom-providers/UsersProvider';

const UserProfileHeaderWrapper = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const Title = styled(Typography)(() => ({
    fontSize: pxToRem(24),
    lineHeight: pxToRem(36),
    fontWeight: 500,
}));

const FullName = styled(Typography)(() => ({
    fontSize: pxToRem(18),
    lineHeight: pxToRem(27),
    whiteSpace: 'nowrap',
    fontWeight: 500,
}));

const CogTypeTag = styled('div')(({ theme }) => ({
    background: '#FFEA9A',
    borderRadius: 100,
    padding: theme.spacing(0.5, 1),
    whiteSpace: 'nowrap',
    fontWeight: 600,
    marginLeft: theme.spacing(1),
}));

const ChevronRight = styled(MuiChevronRight)(({ theme }) => ({
    margin: theme.spacing(0, 2),
}));

export enum CurrentTab {
    Overview = 1,
    Communication,
}

export const UserProfile = () => {
    const { getTeamInsights, getEnterpriseUser } = endpoints();

    const { id } = useParams();

    const {
        state: { teamInsights, enterpriseUser },
        dispatch,
    } = useUsers();

    const [tab, setTab] = useState<CurrentTab>(CurrentTab.Overview);

    useEffect(() => {
        if (id) {
            getData(id);
        }
    }, [id]);

    const getData = async (id: string) => {
        const [{ data: teamInsightsData }, { data: userData }] =
            await Promise.all([
                getTeamInsights(id),
                getEnterpriseUser({ userId: id }),
            ]);

        if (teamInsightsData) {
            dispatch({ type: 'SET_TEAM_INSIGHTS', payload: teamInsightsData });
        }
        if (userData) {
            dispatch({ type: 'SET_ENTERPRISE_USER', payload: userData });
        }
    };

    const handleTabChange = (_event: SyntheticEvent, newValue: CurrentTab) => {
        setTab(newValue);
    };

    const getTabContent = () => {
        switch (tab) {
            case CurrentTab.Overview:
                return <Overview setTab={setTab} />;
            case CurrentTab.Communication:
                return <Communication />;

            default:
                return null;
        }
    };

    if (!teamInsights || !enterpriseUser) {
        return null;
    }

    const fullName = `${enterpriseUser.firstName} ${enterpriseUser.lastName}`;

    const { learner } = teamInsights;

    if (!learner) {
        return null;
    }

    return (
        <>
            <PageHeader
                title={
                    <UserProfileHeader
                        fullName={fullName}
                        cogType={learner.cogType}
                    />
                }
            />
            <TabsRow>
                <TabRowsMaxWidth>
                    <Tabs
                        value={tab}
                        onChange={handleTabChange}
                        aria-label='cognitive map tabs'
                        variant='scrollable'
                        scrollButtons='auto'
                    >
                        <Tab
                            value={CurrentTab.Overview}
                            label='Support overview'
                        />
                        <Tab
                            value={CurrentTab.Communication}
                            label='Communication'
                        />
                    </Tabs>
                </TabRowsMaxWidth>
            </TabsRow>
            <MainContentInner>{getTabContent()}</MainContentInner>
        </>
    );
};

interface IUserProfileHeaderProps {
    fullName: string;
    cogType: CogTypeEnum | null;
}

export const UserProfileHeader: React.FC<IUserProfileHeaderProps> = ({
    fullName,
    cogType,
}) => {
    return (
        <UserProfileHeaderWrapper>
            <Title>My team</Title>
            <ChevronRight size={28} />
            <FullName>{fullName}</FullName>
            {cogType && <CogTypeTag>{CogTypeLookup[cogType]}</CogTypeTag>}
        </UserProfileHeaderWrapper>
    );
};
