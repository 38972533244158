import { Typography, styled } from '@mui/material';

import Icon from '../../../assets/img/icons/caicon.svg';
import { pxToRem } from '../../../utils/style-functions';

const Wrapper = styled('div')(({ theme }) => ({
    background: `linear-gradient(90deg, ${theme.palette.primary.main} 0%, #7458FF 100%)`,
    borderRadius: 100,
    padding: theme.spacing(0.5, 1),
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
    width: 56,
}));

const Text = styled(Typography)(({ theme }) => ({
    color: theme.palette.common.white,
    fontWeight: 600,
    fontSize: pxToRem(12),
    lineHeight: pxToRem(24),
}));

export const KaiTag = () => {
    return (
        <Wrapper>
            <img src={Icon} alt='' /> <Text>Kai</Text>
        </Wrapper>
    );
};
