import {
    IconButton,
    MenuItem,
    Menu as MuiMenu,
    styled,
    useTheme,
} from '@mui/material';
import { MoreVertical, Settings, Trash2, User } from 'react-feather';

import { IUser } from '../../../types';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

const Menu = styled(MuiMenu)(({ theme }) => ({
    '.MuiMenu-paper': {
        boxShadow: theme.cogShadows.lg,
        borderRadius: theme.shape.borderRadius,
    },
}));

interface IOwnProps {
    editUser: () => void;
    deleteUser: () => void;
    rowIsCurrentUser: boolean;
    userId: IUser['id'];
    showProfile: boolean;
}

export const UserRowContextMenu: React.FC<IOwnProps> = ({
    editUser,
    deleteUser,
    rowIsCurrentUser,
    userId,
    showProfile,
}) => {
    const navigate = useNavigate();
    const theme = useTheme();

    const [contextMenu, setContextMenu] = useState<null | HTMLElement>(null);
    const open = Boolean(contextMenu);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setContextMenu(event.currentTarget);
    };

    const editUserHandler = () => {
        editUser();
        setContextMenu(null);
    };

    const deleteUserHandler = () => {
        deleteUser();
        setContextMenu(null);
    };

    const profileHandler = () => {
        navigate(`/users/${userId}/profile`);
        setContextMenu(null);
    };

    return (
        <>
            <IconButton
                onClick={handleClick}
                aria-label='Toggle user context menu'
            >
                <MoreVertical />
            </IconButton>
            <Menu
                anchorEl={contextMenu}
                open={open}
                onClose={() => setContextMenu(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <MenuItem onClick={editUserHandler}>
                    <Settings size={18} style={{ marginRight: 8 }} /> Edit user
                </MenuItem>
                {showProfile && (
                    <MenuItem onClick={profileHandler}>
                        <User size={18} style={{ marginRight: 8 }} /> Profile
                    </MenuItem>
                )}
                {!rowIsCurrentUser && (
                    <MenuItem
                        sx={{
                            color: theme.palette.error.main,
                        }}
                        onClick={deleteUserHandler}
                    >
                        <Trash2 size={18} style={{ marginRight: 8 }} />
                        Delete user
                    </MenuItem>
                )}
            </Menu>
        </>
    );
};
