import { CombinedUser } from '../Edit';
import { Dialog } from '@cognassist/react-components';
import { RemoteKeys } from '../../../../types';
import { endpoints } from '../../../../api/endpoints';
import { useRemoteValidation } from '../../../../custom-hooks/useRemoteValidation';
import { useSnackbar } from 'notistack';
import { useUserFns } from '../../../../custom-hooks/useUserFns';
import { useUsers } from '../../../../custom-providers/UsersProvider';

const DeleteUserValidationMap = {
    userId: 'UserId',
} as const;

type DeleteUserRemoteKeys = RemoteKeys<typeof DeleteUserValidationMap>;

interface IOwnProps {
    handleCloseEditUser: (event: { updateUserTable: boolean }) => void;
}

export const DeleteUser: React.FC<IOwnProps> = ({ handleCloseEditUser }) => {
    const { handleDeleteUserDialog } = useUserFns();
    const { enqueueSnackbar } = useSnackbar();

    const { deleteUser } = endpoints();

    const parseAPIErrors = useRemoteValidation<typeof DeleteUserValidationMap>(
        DeleteUserValidationMap
    );

    const {
        state: {
            activeUser,
            dialogManagement: { deleteUser: open },
        },
    } = useUsers();

    const deleteUserHandler = async (user: CombinedUser) => {
        if (user?.id) {
            const { error } = await deleteUser<DeleteUserRemoteKeys>(user.id);

            if (error) {
                const errors = parseAPIErrors(error);

                if (errors.length) {
                    errors.forEach((error) => {
                        enqueueSnackbar(error.message, { variant: 'error' });
                    });
                } else {
                    enqueueSnackbar('Something went wrong', {
                        variant: 'error',
                    });
                }
            } else {
                enqueueSnackbar(
                    `${user.firstName} ${user.lastName} has been deleted successfully`,
                    { variant: 'success' }
                );
                handleCloseEditUser({ updateUserTable: true });
            }
            handleDeleteUserDialog();
        }
    };

    return (
        <Dialog
            open={open}
            title='Delete user'
            content={`Are you sure you want to delete ${activeUser?.firstName}
                    ${activeUser?.lastName}?`}
            confirmButtonProps={{
                text: 'Yes, delete user',
                onClick: () => deleteUserHandler(activeUser),
            }}
            cancelButtonProps={{
                color: 'inherit',
                text: 'Cancel',
                onClick: () => handleDeleteUserDialog(),
            }}
            disableScrollLock={true}
            disableEnforceFocus={true}
        />
    );
};
