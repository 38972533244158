import { Controller, useForm } from 'react-hook-form';
import { Typography, styled } from '@mui/material';

import { ErrorMessage } from '@hookform/error-message';
import { ErrorText } from '../../../../components/styled-components/Forms';
import Image from '../../../../assets/img/juggling-tasks.svg';
import { KaiTag } from '../../../../components/main-app/cognitive-map-v2/KaiTag';
import { Textbox } from '../../../../components/main-app/cognitive-map-v2/Textbox';
import { pxToRem } from '../../../../utils/style-functions';
import { shouldForwardProps } from '../../../../utils/shouldForwardProp';
import { useCognitiveMap } from '../../../../custom-providers/CognitiveMapProvider';
import { useFeatureIsOn } from '@growthbook/growthbook-react';

const Wrapper = styled('div', {
    ...shouldForwardProps('isDesktop'),
})<{ isDesktop: boolean }>(({ isDesktop, theme }) => ({
    width: '100%',
    height: 'calc(100vh - 125px - 56px)',
    display: 'grid',
    gridTemplateColumns: isDesktop ? '60% 40%' : '1fr',
    [theme.breakpoints.down(744)]: {
        height: 'calc(100vh - 62px - 76px - 56px)',
    },
}));

const LeftSide = styled('div', {
    ...shouldForwardProps('isDesktop'),
})<{ isDesktop: boolean }>(({ isDesktop, theme }) => ({
    padding: isDesktop ? theme.spacing(4) : theme.spacing(3, 2),
    background: theme.palette.common.white,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));

const InnerLeftWrapper = styled('div')(({ theme }) => ({
    maxWidth: 540,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: theme.spacing(3),
}));

const RightSide = styled('div', {
    ...shouldForwardProps('isDesktop'),
})<{ isDesktop: boolean }>(({ isDesktop }) => ({
    display: isDesktop ? 'flex' : 'none',
    justifyContent: 'center',
    alignItems: 'center',
}));

const TextWrapper = styled('div')(() => ({}));

const Title = styled(Typography, {
    ...shouldForwardProps('isMobile'),
})<{ isMobile: boolean }>(({ isMobile, theme }) => ({
    fontWeight: 500,
    fontSize: isMobile ? pxToRem(18) : pxToRem(24),
    lineHeight: isMobile ? pxToRem(27) : pxToRem(36),
    marginBottom: theme.spacing(2),
}));

const SubTitle = styled(Typography, {
    ...shouldForwardProps('isMobile'),
})<{ isMobile: boolean }>(({ isMobile }) => ({
    fontWeight: isMobile ? 400 : 500,
    fontSize: isMobile ? pxToRem(16) : pxToRem(18),
    lineHeight: isMobile ? pxToRem(24) : pxToRem(27),
}));

const TextboxWrapper = styled('div')(() => ({}));

interface IOwnProps {
    fullName?: string;
}

interface IFormFields {
    task: string;
}

const CognitiveMapMyJob: React.FC<IOwnProps> = ({ fullName }) => {
    const featureTaskStrategies = useFeatureIsOn('task-strategies');

    const {
        state: { screenSize },
    } = useCognitiveMap();

    const methods = useForm<IFormFields>({
        defaultValues: {
            task: '',
        },
    });

    const {
        watch,
        handleSubmit,
        formState: { errors },
        control,
    } = methods;

    const onSubmit = () => {
        handleSubmit((values: IFormFields) => {
            console.log(values.task);
        })();
    };

    const firstName = fullName?.split(' ')[0];
    const isDesktop = !screenSize.isMobile && !screenSize.isTablet;

    if (!featureTaskStrategies) {
        return null;
    }

    return (
        <Wrapper isDesktop={isDesktop}>
            <LeftSide isDesktop={isDesktop}>
                <InnerLeftWrapper>
                    <KaiTag />
                    <TextWrapper>
                        <Title variant='h2' isMobile={screenSize.isMobile}>
                            Hey {firstName} &#128075;, what are you working on
                            at the moment?
                        </Title>
                        <SubTitle variant='h4' isMobile={screenSize.isMobile}>
                            I&apos;ll create a strategy for your brain
                        </SubTitle>
                    </TextWrapper>
                    <TextboxWrapper>
                        <Controller
                            name='task'
                            control={control}
                            defaultValue={watch('task')}
                            rules={{
                                required:
                                    'Please enter a task you are currently working on.',
                                maxLength: {
                                    value: 500,
                                    message:
                                        'You have gone over the 500 character limit, please reduce it and try again.',
                                },
                            }}
                            render={({ field: { value, onChange } }) => (
                                <Textbox
                                    id='task'
                                    hasError={!!errors.task}
                                    onChange={onChange}
                                    value={value}
                                    onSubmit={onSubmit}
                                    placeholder='What are you working on?'
                                    fullWidth
                                    multiline
                                    isMobile={screenSize.isMobile}
                                />
                            )}
                        />
                        <ErrorMessage
                            errors={errors}
                            name='task'
                            render={({ message }) => (
                                <ErrorText>{message}</ErrorText>
                            )}
                        />
                    </TextboxWrapper>
                </InnerLeftWrapper>
            </LeftSide>
            <RightSide isDesktop={isDesktop}>
                <img src={Image} alt='' />
            </RightSide>
        </Wrapper>
    );
};

export default CognitiveMapMyJob;
