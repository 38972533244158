import { Theme, styled } from '@mui/material';
import { useEffect, useRef, useState } from 'react';

import { pxToRem } from '../../../../utils/style-functions';
import { useDebounce } from '../../../../custom-hooks/useDebounce';

const BarWrapper = styled('div')(() => ({
    display: 'flex',
    flexBasis: '100%',
    flexFlow: 'row wrap',
    justifyContent: 'center',
}));

const BarInnerWrapper = styled('div')(() => ({
    display: 'flex',
    flexBasis: '100%',
}));

const BarEdgeLeft = styled('div')(() => ({
    background: 'rgba(73,48,218,1)',
    width: 10,
    height: 16,
    borderBottomLeftRadius: 100,
    borderTopLeftRadius: 100,
}));

const BarEdgeRight = styled('div')(() => ({
    background: 'rgba(127,191,255,1)',
    width: 10,
    height: 16,
    borderBottomRightRadius: 100,
    borderTopRightRadius: 100,
}));

const Bar = styled('div')(() => ({
    background:
        'linear-gradient(90deg, rgba(73,48,218,1) 0%, rgba(127,191,255,1) 100%)',
    height: 16,
    flexBasis: '100%',
}));

const scoreToolipStyles = (theme: Theme) => {
    return {
        width: 'auto',
        padding: theme.spacing(0, 2),
        height: 40,
        background: '#4A4C5E',
        borderRadius: theme.shape.borderRadius,
        color: theme.palette.common.white,
        fontSize: pxToRem(13),
        fontWeight: 500,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        transition: 'transform 0.3s ease-in-out',
        '&:after': {
            content: "''",
            height: 7,
            width: 25,
            background: '#4A4C5E',
            display: 'block',
            position: 'absolute',
        },
    };
};

const ScoreTooltip = styled('div')(({ theme }) => ({
    ...scoreToolipStyles(theme),
    marginTop: theme.spacing(1),
    '&:after': {
        ...scoreToolipStyles(theme)['&:after'],
        transform:
            'skew(20deg, 20deg) rotate(-45deg) translateX(18px) translateY(-10px)',
    },
}));

const ScoreTooltipTop = styled('div')(({ theme }) => ({
    ...scoreToolipStyles(theme),
    marginBottom: theme.spacing(1),
    '&:after': {
        ...scoreToolipStyles(theme)['&:after'],
        transform:
            'skew(20deg, 20deg) rotate(-45deg) translateX(-18px) translateY(10px)',
    },
}));

interface IOwnProps {
    userScore: number | null;
    employeeScore?: number | null;
    viewingAsManager: boolean;
    employeeName?: string;
}

const ProcessingInformationBar: React.FC<IOwnProps> = ({
    userScore,
    employeeScore,
    employeeName,
}) => {
    const barRef = useRef<HTMLDivElement>(null);
    const userScoreRef = useRef<HTMLDivElement>(null);
    const employeeScoreRef = useRef<HTMLDivElement>(null);

    const [barWidth, setBarWidth] = useState<number>(0);

    useEffect(() => {
        setBarWidth(barRef.current?.clientWidth ?? 0);
        window.addEventListener('resize', debounceResize);

        return () => {
            window.removeEventListener('resize', debounceResize);
        };
    }, []);

    useEffect(() => {
        plotScore();
    }, [barWidth, userScore, employeeScore]);

    const debounceResize = useDebounce(() => {
        setBarWidth(barRef.current?.clientWidth ?? 0);
    }, 500);

    const plotScore = () => {
        if (userScoreRef.current && userScore) {
            userScoreRef.current.style.transform = `translateX(${
                (barWidth / 12) * userScore
            }px)`;
        }
        if (employeeScoreRef.current && employeeScore) {
            employeeScoreRef.current.style.transform = `translateX(${
                (barWidth / 12) * employeeScore
            }px)`;
        }
    };

    if (!userScore && !employeeScore) {
        return null;
    }

    return (
        <BarWrapper>
            {userScore && (
                <ScoreTooltipTop ref={userScoreRef}>You</ScoreTooltipTop>
            )}
            <BarInnerWrapper>
                <BarEdgeLeft />
                <Bar ref={barRef} />
                <BarEdgeRight />
            </BarInnerWrapper>

            {employeeScore && (
                <ScoreTooltip ref={employeeScoreRef}>
                    {employeeName}
                </ScoreTooltip>
            )}
        </BarWrapper>
    );
};

export default ProcessingInformationBar;
