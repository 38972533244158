import React, { useState } from 'react';

import { CombinedUser } from '../Edit';
import { Dialog } from '@cognassist/react-components';
import { endpoints } from '../../../../api/endpoints';
import { useSnackbar } from 'notistack';
import { useUserFns } from '../../../../custom-hooks/useUserFns';
import { useUsers } from '../../../../custom-providers/UsersProvider';

interface IOwnProps {
    handleCloseEditUser: (event: { updateUserTable: boolean }) => void;
}

export const RemoveProfilePictureDialog: React.FC<IOwnProps> = ({
    handleCloseEditUser,
}) => {
    const { handleRemoveProfilePictureDialog } = useUserFns();

    const {
        state: {
            dialogManagement: { removeProfilePicture: open },
            activeUser,
        },
    } = useUsers();

    const { deleteUserProfilePicture } = endpoints();

    const { enqueueSnackbar } = useSnackbar();

    const [removeProfilePictureLoading, setRemoveProfilePictureLoading] =
        useState<boolean>(false);

    const removeProfilePictureHandler = async (user: CombinedUser) => {
        if (user?.id) {
            setRemoveProfilePictureLoading(true);

            const { error } = await deleteUserProfilePicture(user.id);

            if (error) {
                enqueueSnackbar(
                    `Could not remove ${user?.firstName} ${user?.lastName}'s profile picture, please try again.`,
                    {
                        variant: 'error',
                    }
                );
            } else {
                enqueueSnackbar(
                    `${user?.firstName} ${user?.lastName}'s profile picture has been removed.`,
                    {
                        variant: 'success',
                    }
                );
                handleCloseEditUser({ updateUserTable: true });
            }

            handleRemoveProfilePictureDialog();
            setRemoveProfilePictureLoading(false);
        }
    };

    return (
        <Dialog
            open={open}
            title='Remove profile picture'
            content={`Are you sure you want to remove ${activeUser?.firstName} ${activeUser?.lastName}'s profile picture? (This cannot be undone)`}
            confirmButtonProps={{
                text: 'Yes',
                loading: removeProfilePictureLoading,
                onClick: () => removeProfilePictureHandler(activeUser),
            }}
            cancelButtonProps={{
                color: 'inherit',
                text: 'Cancel',
                onClick: () => handleRemoveProfilePictureDialog(),
            }}
            disableScrollLock={true}
            disableEnforceFocus={true}
        />
    );
};
