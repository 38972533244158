import { CombinedUser } from '../components/main-app/users/Edit';
import { useUsers } from '../custom-providers/UsersProvider';

export const useUserFns = () => {
    const { dispatch } = useUsers();

    const handleDeleteUserDialog = (user?: CombinedUser) => {
        if (user) {
            dispatch({ type: 'SET_ACTIVE_USER', payload: user });
            dispatch({
                type: 'SET_DIALOG_MANAGEMENT',
                payload: {
                    key: 'deleteUser',
                    value: true,
                },
            });
        } else {
            dispatch({
                type: 'SET_DIALOG_MANAGEMENT',
                payload: {
                    key: 'deleteUser',
                    value: false,
                },
            });
            dispatch({ type: 'SET_ACTIVE_USER', payload: null });
        }
    };

    const handleRemoveProfilePictureDialog = (user?: CombinedUser) => {
        if (user) {
            dispatch({ type: 'SET_ACTIVE_USER', payload: user });
            dispatch({
                type: 'SET_DIALOG_MANAGEMENT',
                payload: {
                    key: 'removeProfilePicture',
                    value: true,
                },
            });
        } else {
            dispatch({ type: 'SET_ACTIVE_USER', payload: null });
            dispatch({
                type: 'SET_DIALOG_MANAGEMENT',
                payload: {
                    key: 'removeProfilePicture',
                    value: false,
                },
            });
        }
    };

    return {
        handleDeleteUserDialog,
        handleRemoveProfilePictureDialog,
    };
};
